import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
gsap.registerPlugin(ScrollTrigger, SplitText, DrawSVGPlugin)

/**
 * All animations for different parts of the DOM
 */

export const header = () => {
  if (!domStorage.header) return

  const tween = gsap.fromTo(domStorage.header, {
    yPercent: -30,
    opacity: 0,
  }, {
    yPercent: 0,
    opacity: 1,
    ease: 'expo.inOut',
    duration: 1.5,
    onComplete: () => {
      gsap.set(domStorage.header, { clearProps: 'yPercent,scale,opacity' })
    }
  })

  return tween
}

export const basicIntro = () => {
  const children = [...viewStorage.current.querySelectorAll('.container > *:not(.head)')]

  const tl = gsap.timeline({
    defaults: { ease: 'expo.inOut', duration: 1.5 },
    onComplete: () => {
      if (children) gsap.set(children, { clearProps: 'y,opacity' })
    }
  })

  if (children && children.length) tl.from(children, { y: 100, opacity: 0 }, 0.035)

  return tl
}

export const pageHeaderIntro = () => {
  const { current } = viewStorage
  const pageHeader = current.querySelector('.PageHeader')

  if (!pageHeader) return

  /**
   * DOM
   */
  const h1 = pageHeader.querySelector('h1')
  const button = pageHeader.querySelector('.Btn')
  const nav = pageHeader.querySelector('.container > nav')
  const backgroundVisual = pageHeader.querySelector(':scope > .visual__container .visual')
  const sections = [...viewStorage.current.children].slice(1)

  /**
   * Main Timeline
   */
  const tl = gsap.timeline({
    defaults: { ease: 'power3.inOut', duration: 1 },
  })

  /**
   * General
   */

  if (h1) {
    tl.from(h1, { 
      y: 20,
      opacity: 0,
      duration: 1, 
      clearProps: 'y,opacity'
    }, 0)
  }

  if (button) {
    tl.from(button, { 
      y: 20, 
      opacity: 0, 
      clearProps: 'y,opacity' 
    }, '-=1')
  }

  if (nav) {
    tl.from(nav, { 
      y: 20, 
      opacity: 0, 
      clearProps: 'y,opacity' 
    }, '-=1')
  }

  if (backgroundVisual) {
    tl.from(backgroundVisual, { 
      scale: 1.05,
    }, '-=1')
  }

  /**
   * Pages
   */

  // Page: Home
  if (current.classList.contains('Home')) {
    const featuredEvents = viewStorage.current.querySelector('.FeaturedEvents')

    tl.from(featuredEvents, {
      y: 0, 
      opacity: 0,
      onComplete: () => gsap.set(sections, { clearProps: 'y,opacity' }) 
    }, '-=0.5')
  }

  if (current.classList.contains('VisitMonastery')) {
    const visualBanner = viewStorage.current.querySelector('.visual-banner')

    tl.from(visualBanner, { 
      y: 20, 
      opacity: 0,
      onComplete: () => gsap.set(sections, { clearProps: 'y,opacity' }) 
    }, '-=1')
  }

  return tl
}

// Page: Basic
export const pageSections = () => {
  const sections = [...viewStorage.current.children].slice(1)

  if (!sections && !sections.length) return

  const tween = gsap.from(sections, { 
    y: 20, 
    opacity: 0, 
    ease: 'power3.out', 
    duration: 1, 
    onComplete: () => gsap.set(sections, { clearProps: 'y,opacity' }) 
  })

  return tween
}
